import React, { useState } from 'react';
import { Modal, Button, ListGroup, Row, Col } from 'react-bootstrap';

const NewAdmissionModal = ({ show, handleClose, showAlert, handleRefresh }) => {
  const [admissionGroup, setAdmissionGroup] = useState([]);
  const [groupTotal, setGroupTotal] = useState(0);

  const handleAddAdmissionToGroup = (type) => {
    const newAdmission = { type, time: new Date() };
    setAdmissionGroup([...admissionGroup, newAdmission]);

    const admissionValue = parseInt(type.replace('$', ''), 10);
    setGroupTotal(groupTotal + admissionValue);
  };

  const handleSubmitGroup = async () => {
    // Send the group admissions to the database
    await Promise.all(
      admissionGroup.map((admission) =>
        fetch('https://admissions.everyonespinballclub.workers.dev/api/admissions', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ type: admission.type }),
        })
      )
    );

    // Clear the group admissions after submission
    setAdmissionGroup([]);
    setGroupTotal(0);

    // Close modal
    handleClose();

    // Show alert
    showAlert('success', 'Group Submitted');
    handleRefresh();
  };

  // Clear the group when modal is closed
  const handleCancel = () => {
    setAdmissionGroup([]); // Clear the group
    setGroupTotal(0); // Reset the total
    handleClose();
  };

  // Remove admission from the group
  const handleRemoveAdmission = (type) => {
    const updatedGroup = [...admissionGroup];
    const index = updatedGroup.findIndex((admission) => admission.type === type);
    if (index !== -1) {
      updatedGroup.splice(index, 1);
      const admissionValue = parseInt(type.replace('$', ''), 10);
      setGroupTotal(groupTotal - admissionValue);
      setAdmissionGroup(updatedGroup);
    }
  };

  // Group the admissions by type and calculate totals
  const groupedAdmissions = admissionGroup.reduce((acc, curr) => {
    const existingEntry = acc.find((entry) => entry.type === curr.type);
    if (existingEntry) {
      existingEntry.count += 1;
    } else {
      acc.push({ type: curr.type, count: 1 });
    }
    return acc;
  }, []);

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>New Admission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Admission buttons */}
        <Row className="mb-4">
          <Col>
            <Button
              variant="primary"
              onClick={() => handleAddAdmissionToGroup('$15')}
              className="w-100 mb-2"
              style={{ padding: '15px', fontSize: '1.2rem' }}
            >
              Add $15 Admission
            </Button>
          </Col>
          <Col>
            <Button
              variant="secondary"
              onClick={() => handleAddAdmissionToGroup('$10')}
              className="w-100 mb-2"
              style={{ padding: '15px', fontSize: '1.2rem' }}
            >
              Add $10 Admission
            </Button>
          </Col>
          <Col>
            <Button
              variant="success"
              onClick={() => handleAddAdmissionToGroup('$5')}
              className="w-100 mb-2"
              style={{ padding: '15px', fontSize: '1.2rem' }}
            >
              Add $5 Admission
            </Button>
          </Col>
        </Row>

        {/* Group summary */}
        {admissionGroup.length > 0 && (
          <div>
            <h5>Group Summary</h5>
            <ListGroup>
              {groupedAdmissions.map((entry, index) => (
                <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                  <span>{entry.count} x {entry.type}</span>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveAdmission(entry.type)}
                    className="ml-auto"
                    size="sm"
                    style={{ marginLeft: 'auto' }}
                  >
                    X
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <h6 className="mt-3">Total: ${groupTotal}</h6>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleCancel} className="w-100" style={{ padding: '15px', fontSize: '1.2rem' }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmitGroup} disabled={admissionGroup.length === 0} className="w-100" style={{ padding: '15px', fontSize: '1.2rem' }}>
          Submit Group
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewAdmissionModal;
